@use "../../abstracts/variables/colors";
@use "../../abstracts/variables/typography";

#page-menu {
	ul {
		li {
			font-family: typography.$font__header;
			text-transform: uppercase;
			position: relative;
			a {
				color: colors.$color__secondary;
				transition: 0.3s ease-in-out;
				font-size: 2rem;
				position: relative;
				font-weight: 900;

				span {
					&::before {
						content: attr(data-number-decoration);
						position: absolute;
						display: block;
						left: -1.75rem;
						top: 1.8375rem;
						font-size: .75rem;
						letter-spacing: .15px;
						font-weight: 900;
						text-transform: uppercase;
						transform: rotate(-90deg);
					}
				}

				&:hover {
					color: colors.$color__main;
				}

				&.active {
					color: colors.$color__main;

					&::after {
						transform: scaleX(1);
					}
				}

				&::after {
					content: "";
					position: absolute;
					bottom: 0;
					left: 0;
					display: block;
					width: 30%;
					height: 3px;
					background: colors.$color__main;
					transform: scaleX(0);
					transition: transform .5s cubic-bezier(.6,0,0,1);
				}
			}
		}
	}
}

#top-menu {
	font-family: typography.$font__header;
	text-transform: uppercase;

	ol {
		li {
			a {

				&.active {

					&:before {
						transform: scaleX(1);
					}
				}

				&:before {
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					display: block;
					width: 100%;
					height: 3px;
					background: colors.$color__main;
					transform: scaleX(0);
					transition: transform .5s cubic-bezier(.6,0,0,1);
				}
			}
		}
	}
}